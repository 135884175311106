/*
 * @Description: 网络请求
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-06-20 16:42:33
 * @FilePath: /JinChanTuoKe-Front/src/utils/request.js
 */
import axios from "axios";
import httpConfig from ".";
import { Message } from "element-ui";
import { getToken } from "./common";
import router from "../router/index";

const { URL_PREFIX_API } = httpConfig;

// -----------------------------------分割线-----------------------------------//
const requestList = new Set();
// const { CancelToken } = axios;

// axios.defaults.baseURL = import.meta.env.VITE_BASE_HTTP;
/**
 * 请求拦截
 */
axios.interceptors.request.use(
  (config) => {
    config.headers["token"] = getToken() || "";
    // config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.headers["Content-Type"] = "application/json";
    /** 发起请求时，取消掉当前正在进行的相同请求 */
    // config.cancelToken = new CancelToken((e) => {
    //   // eslint-disable-next-line no-unused-expressions
    //   if (requestList.has(config.url)) {
    //     e(`${window.location.host}/${config.url}---重复请求被中断`);
    //   } else {
    //     requestList.add(config.url);
    //   }
    // });

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    requestList.delete(response.config.url);
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      /** 请求取消 */
      console.warn(error);
    }
  }
);
/**
 * ajax请求通用方法，基于axios
 * @param {String} method   异步请求的方式，如：post、get等
 * @param {String} url      异步请求的地址
 * @param {Object} data     发送的数据
 * @param {Boolean} loading 全局loading
 * @param {Boolean} withCredentials
 */
export const HTTP = (params, status = 1) => {
  const { method = "post", url, data = {}, withCredentials = false } = params;
  const postUrl = `${URL_PREFIX_API[data.apiNum || 1]}${url}`;
  delete data.apiNum;
  delete data.contentType;
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: postUrl,
      ...(method === "post"
        ? {
            data,
          }
        : {
            params: data,
          }),
      withCredentials,
    })
      .then((res) => {
        const result = res?.data || {};
        if (status === 2) {
          resolve(result.code);
        } else if (status === 3) {
          resolve(result);
        } else {
          if (result.code === 200) {
            resolve(result.data);
          } else {
            if (result.message) {
              Message.error(result.message);
            }
            if (result.code === 401) {
              router.push({
                name: "Login",
              });
            }
            reject(result);
            // if ([90002, 105].includes(result.code)) {
            // 	setToken('')
            // 	// Bridge.callHandler('invalid')
            // }
          }
        }
      })
      .catch((error) => {
        // Toast.clear()
        reject(error);
      });
  });
};
