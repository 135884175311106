/*
 * @Description: 公共方法
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-21 21:28:45
 * @FilePath: /JinChanTuoKe-Front/src/utils/common.js
 */

// 标识
const tokenKey = "Authorization";

/** 判断PC */
export const isPC = () => {
  let userAgentInfo = navigator.userAgent;
  let agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let flag = true;
  for (let i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};

// 设置缓存 默认localStorage
export const setCookie = (key, value, type = true) => {
  if (type) {
    window.localStorage.setItem(key, JSON.stringify(value));
  } else {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

// 获取token
export const setToken = (token) => {
  setCookie(tokenKey, token);
};

// 获取缓存 默认localStorage
export const getCookie = (key, type = true) => {
  const getItem = type
    ? localStorage.getItem(key)
    : sessionStorage.getItem(key);
  return getItem ? JSON.parse(getItem) : "";
};

export const getToken = () => {
  const token = getCookie(tokenKey);
  if (token) {
    return token;
  }
};

let fileInputEle;
let fileInputHandlerDefer = {};
const getFile = (limitTypes, multiple, type) => {
  // 创建 input 节点
  fileInputEle = document.createElement("input");
  // 将 input 类型改为文件 type = file
  fileInputEle.setAttribute("type", "file");
  fileInputEle.setAttribute(type, true);
  // 不显示
  fileInputEle.style.display = "none";
  // 内容改变事件
  fileInputEle.addEventListener("change", () => {
    if (fileInputEle.files && fileInputEle.files.length) {
      // multiple ：true 可以多图片上传, false 单图片上传
      if (multiple) {
        fileInputHandlerDefer.resolve(fileInputEle.files);
      } else {
        fileInputHandlerDefer.resolve(fileInputEle.files[0]);
      }
    } else {
      fileInputHandlerDefer.reject();
    }
  });

  // 重置 multiple, accept 属性
  fileInputEle.removeAttribute("multiple");
  fileInputEle.removeAttribute("accept");
  fileInputEle.value = "";
  if (multiple) {
    fileInputEle.setAttribute("multiple", "multiple");
  }
  // 设置上传图片约束
  if (limitTypes && limitTypes.length) {
    fileInputEle.setAttribute(
      "accept",
      limitTypes.map((one) => `.${one}`).join(",")
    );
  }
  return new Promise((resolve, reject) => {
    fileInputHandlerDefer.resolve = resolve;
    fileInputHandlerDefer.reject = reject;
    fileInputEle.click();
  });
};

export const uploadImage = async (
  types = ["jpeg", "jpg", "png"],
  multiple = false,
  type = "text"
) => {
  const files = await getFile(types, multiple, type);
  return files;
  //   var reader = new FileReader();
  //   reader.readAsDataURL(files);
  //   reader.onloadend = () => {
  //     return {
  //       result: reader.result,
  //       files,
  //     };
  //   };
  //   const formData = new FormData();
  //   formData.append("fileUpload", files);
  //   formData.append("type", "website");
  //   console.log(formData);
  //   const url = `https://dmstest.bendamotor.cn/api.php?m=upload&subm=common`;
  //   const resp = await fetch(url, {
  //     method: "POST",
  //     body: formData,
  //   });
  //   const res = await resp.json();
  //   if (res.status) {
  //     return res.data.filepath;
  //   }
  //   return res.data.filepath;
};

/**
 * 生成唯一key
 * @returns uuid
 */
export const genUuid = () =>
  new Date().getTime() + Math.random().toString(36).substr(2);
