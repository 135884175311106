/*
 * @Description: 请求配置
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-06-20 15:50:05
 * @FilePath: /JinChanTuoKe-Front/src/utils/index.js
 */
export default {
  URL_PREFIX_API: {
     1: "https://api.aboostify.com/",
    // 1: "https://traffic.zealytech.com/",
    // 1: "http://127.0.0.1:8888/",
  },
};
